.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.amplify-row {
  width: 100%;
  padding: 5px;
}

.amplify-select-domain {
  margin-left: 5%;
  width: 10%;
  display: inline-block;
}

amplify-select::part(select) {
  max-width: 12rem !important;
}

.amplify-row-element-50 {
  width: 50%;
  display: inline-block;
  word-wrap: break-word;
}

.ui-select {
  padding: 1rem 1.75rem 1rem 1rem;
  font-size: 14px;
  color: rgb(21, 41, 57);
  background-color: rgb(255, 255, 255);
  border: 1px solid #c4c4c4;
  border-radius: 3px 0 0 3px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex-basis: auto;
  width: fit-content;
  margin: 0;
  height: 3.125rem;
  max-width: 12rem;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 1rem) calc(1em + 0.5rem),
    calc(100% - 0.7rem) calc(1em + 0.5rem),
    calc(100% - 2.5em) 0.5em;
  background-size: 6px 5px, 6px 5px, 0px 1.5em;
  background-repeat: no-repeat;
}

.ui-select:focus {
  outline: none;
  border-color: #c4c4c4;
} 

.signout {
  margin-top: -25px;
}

.signout-button {
  background: none !important;
  color: orange;
  text-align: left;
  width: 2px;
  text-transform: none;
  letter-spacing: normal;
  margin-left: 1px;
  margin-top: -50px;
  padding: 0;
  font-weight: normal;
  font-size: 12px;
}